import { cloneDeep } from "lodash";
import React from "react";
import { useState } from "react";
import { Cell, Grid, Row } from "../../FoundationGrid";
import MainPageSimpleContentGrid from "../../mainpage/MainPageSimpleContentGrid";
import Section from "../../Section";
import Popup from "../../Popup";
import { getImageUrl } from "../../../api/Api";
import { translate } from "../../../helpers/I18n";
import Icon from "../../Icon";
import CallToAction from "../../CallToAction";

function AppsExamples({ page }) {
  const [selectedEntry, setSelectedEntry] = useState(null);

  const onClick = ({ entry }) => {
    setSelectedEntry(cloneDeep(entry));
  };

  const onClose = () => {
    setSelectedEntry(null);
  };

  const actions = {
    onClick,
    onClose,
  };

  if (
    !page ||
    !page.parts ||
    !page.parts.mainPageSpecialSections ||
    Object.keys(page.parts.mainPageSpecialSections).length === 0
  )
    return null;

  const section = page.parts.mainPageSpecialSections["apps.examples"];

  if (!section) return null;

  return (
    <Section>
      <h2>{section.Title}</h2>
      <div
        dangerouslySetInnerHTML={{ __html: section.IntroText }}
        className="introtext"
      />
      <MainPageSimpleContentGrid
        page={page}
        entries={section.PartsSimpleContent}
        actions={actions}
      />
      <div
        dangerouslySetInnerHTML={{ __html: section.Content }}
        className="introtext"
        style={{ marginTop: "60px" }}
      />
      <CallToAction type="box" cta={page.ctas.home} />
      {selectedEntry && (
        <ExamplePopup entry={selectedEntry} page={page} actions={actions} />
      )}
    </Section>
  );
}

const ExamplePopup = ({ entry, page, actions }) => {
  return (
    <Popup
      size="small"
      image={getImageUrl(entry.Image)}
      show={true}
      onClose={actions.onClose}
    >
      <h3>{entry.Title}</h3>
      <div dangerouslySetInnerHTML={{ __html: entry.Text }} />
      <div className="text-center">
        <button className="text-link" onClick={actions.onClose}>
          <Icon icon="times" left />
          {translate("close", page.locale)}
        </button>
      </div>
    </Popup>
  );
};

export default AppsExamples;
